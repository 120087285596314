import './MainImage.scss'
import { useContext } from 'react'
import { SeriesContext } from '../../utils/SeriesContext'

function MainImage() {

    const { mainInfos } = useContext(SeriesContext);
    if (!mainInfos || mainInfos.length === 0) {
        return null; // Ne rien afficher si mainInfos n'existe pas ou est vide
    }
    
    const mainInfosObject = mainInfos[0];

    return (
        <aside className='mainImageAside'>
            <div>
                <img src={mainInfosObject.mainImageUrl} alt={`Florian_Langellier_${mainInfosObject._id}`} className='mainImageAside_image'/>
            </div>      
        </aside>
    )   
}

export default MainImage