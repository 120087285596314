import './Works.scss'
import Gallery from '../../components/Gallery/Gallery'
import { useContext, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { SeriesContext } from '../../utils/SeriesContext'


function Works() {

    const navigate = useNavigate();
    const { seriesList, handleSelectedSerie} = useContext(SeriesContext);
    const { id } = useParams();
    const index = seriesList.findIndex((serie) => serie._id === id);

    const serie = seriesList[index];

    useEffect(() => {
        if (index === -1) {
          navigate('*');
        }
        if (id) {
            handleSelectedSerie(serie);
        } else {
            handleSelectedSerie('');
        }
        
    }, [index, navigate, handleSelectedSerie, serie, id]);
    
    if (index === -1) {
    return null;
    }
    
    /*-------------
    ----- JSX -----
    -------------*/

    return (
        <section className='worksSection'>
            <Gallery serie={serie}/>
        </section>
    )
}

export default Works