import './AuthModal.scss'
import React, { useContext, useState } from 'react'
import { SeriesContext } from '../../utils/SeriesContext'
import { useForm } from "react-hook-form"
import { useNavigate } from 'react-router-dom'
import { API_URL } from '../../utils/constants'

function AuthModal({ handleAuthModal, authModalDisplay }) {

    const { setLoggedIn, setLoggedOut } = useContext(SeriesContext);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();

    /*------------------------
    ----- FONCTION LOGIN -----
    ------------------------*/

    const onSubmit = async (data) => {
        const { username, password } = data;

        const authData = {
            username,
            password
        };

        try {
            const response = await fetch(`${API_URL}/api/users`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(authData)
            });

            const data = await response.json();

            if (data.token) {
                setDisplayError(false);
                console.log("autorisé");
                const tokenValue = JSON.stringify(data.token);
                const token = tokenValue.replace(/"/g, '');
                setLoggedIn();
                handleAuthModal();
                navigate('/edit');
                window.sessionStorage.setItem("1", token);
            } else {
                console.log("non autorisé");
                setDisplayError(true);
                setLoggedOut();
            }
        } catch (error) {
            console.error(error);
        }
    }

    /*-------------------------------------------------
    ----- GESTION OUVERTURE / FERMETURE ERRORTEXT -----
    -------------------------------------------------*/
    
    const [displayError, setDisplayError] = useState(false);

    function closeAuthModal() {
        handleAuthModal();
        setDisplayError(false);
    }

    /*-------------
    ----- JSX -----
    -------------*/
    
    return (
        <div className={authModalDisplay === true ? 'authModal authModal--displayOn' : 'authModal authModal--displayOff'}>
            <form onSubmit={handleSubmit(onSubmit)} className='authModal_form'>
                <p className='authModal_form_title'>AUTHENTIFICATION</p>
                <p className='authModal_form_alert'></p>
                <div className='authModal_form_usernameField'>
                    <label>USERNAME</label>
                    <input type='text' name='username' defaultValue='' {...register("username", { required: true })}></input>
                    {errors.username && <p className='authModal_alertTextStyle--displayOn'>Champ 'user' requis</p>}
                </div>
                <div className='authModal_form_passwordField'>
                    <label>PASSWORD</label>
                    <input type='password' name='password' defaultValue='' {...register("password", { required: true })}></input>
                    {errors.password && <p className='authModal_alertTextStyle--displayOn'>Champ 'password' requis</p>}
                </div>
                <p className={displayError === true ? 'authModal_alertTextStyle--displayOn' : 'authModal_alertTextStyle--displayOff'}>Utilisateur non-autorisé</p>
                <button type='submit' aria-label="Soumettre le formulaire d'authentification" className='authModal_form_button'>LOGIN</button>
            </form>
            <button onClick={closeAuthModal} aria-label="Fermer la modale d'authentification" className='authModal_form_button'>CLOSE</button>
        </div>
    )
}

export default AuthModal