
import "./Events.scss"
import { useContext } from 'react'
import { SeriesContext } from '../../utils/SeriesContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCalendarPlus, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'

function Events() {

    const { eventsList } = useContext(SeriesContext);

    // Ne rien afficher si mainInfos n'existe pas ou est vide
    if (!eventsList || eventsList.length === 0) {
        return null; 
    }

    /*---------------------------------------------------------------------
    ----- ORGANISATION DES ÉVÈNEMENTS PAR ORDRE CHRONOLOGIQUE INVERSÉ -----
    ---------------------------------------------------------------------*/
    
    const sortedEventsList = eventsList.sort(function(a,b){
        return new Date(b.date) - new Date(a.date);
      });

    const googleEventCalendarUrlMount = (eventData) => {
        const eventDate = eventData.date;
        const eventTimePart = eventDate.substring(11, 16).replace(":", "");
        const eventDatePart = eventDate.substring(0, 10).replace(/-/g, "");
        const eventDateFormat = eventDatePart + "T" + eventTimePart;
        const googleEventCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${eventData.name}&dates=${eventDateFormat}/${eventDateFormat}&details=${eventData.description}&location=${eventData.location}&trp=false&sprop=&sprop=name:`;
        console.log(googleEventCalendarUrl);
        return googleEventCalendarUrl
    }

    /*-------------
    ----- JSX -----
    -------------*/

    return (
        <article className='eventsArticle'>
            <ul className='eventsArticle_list'>
                {sortedEventsList.map((eventData) => (
                    <li className='eventsArticle_list_item' key={eventData.name}>
                        <div className='eventsArticle_list_item_date'>
                            <p className='eventsArticle_list_item_date_day'>{eventData.date.substring(8, 10)}</p>
                            <p className='eventsArticle_list_item_date_monthAndYear'>{eventData.date.substring(5, 7)} {eventData.date.substring(2, 4)}</p>
                            <p className='eventsArticle_list_item_date_time'>{eventData.date.substring(11,16)}</p>
                            <a className='eventsArticle_list_item_date_calendarLink' target="_blank" rel="noreferrer"
                                href={googleEventCalendarUrlMount(eventData)}>
                                <FontAwesomeIcon icon={faCalendarPlus}/>
                            </a>
                        </div>
                        <div className='eventsArticle_list_item_misc' >
                            <h3 className='eventsArticle_list_item_misc_name'>{eventData.name}</h3>
                            <p className='eventsArticle_list_item_misc_description'>{eventData.description}</p>
                            <p className='eventsArticle_list_item_misc_location'>{eventData.location}</p>
                            <a href={eventData.eventUrl} target="_blank" rel="noreferrer" className='eventsArticle_list_item_misc_eventUrl'>{eventData.name}    <FontAwesomeIcon className='eventsArticle_list_item_misc_eventUrl_icon'icon={faArrowUpRightFromSquare}/></a>
                        </div>
                    </li>
                ))}
            </ul>
        </article>
    )
}

export default Events