import './ConfirmBox.scss'
    
function ConfirmBox({ closeConfirmBox, deleteEvent, deleteSerie, confirmBoxState, serieToDelete, deleteMode, eventToDelete }) {
    
    return (
        <div className={confirmBoxState === false ? "editWorkModal_confirmBox editWorkModal_confirmBox--displayOff" : "editWorkModal_confirmBox editWorkModal_confirmBox--displayOn"}>
            <div className='editWorkModal_confirmBox_container'>
                <p className='editWorkModal_confirmBox_container_question'>{deleteMode==='serie' ? 'Voulez-vous vraiment supprimer cette série?' : 'Voulez-vous vraiment supprimer cet event?'}</p>
                <div className='editWorkModal_confirmBox_container_buttons'>
                    <button aria-label="Valider la suppression de la série" onClick={() => {deleteMode==='serie' ? deleteSerie(serieToDelete) : deleteEvent(eventToDelete)}}>OUI</button>
                    <button aria-label="Annuler la suppression de la série" onClick={() => closeConfirmBox () }>NON</button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmBox