import './ImageModal.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronLeft , faChevronRight} from '@fortawesome/free-solid-svg-icons'

function ImageModal ({imageModalDisplay, setImageModalDisplay, imageModalSrc, displayPrevImage, displayNextImage, imagesArrayLength}) {

    function closeModal () {
        setImageModalDisplay(false)
    }

    return (
        <aside className={imageModalDisplay === false ? 'imageModalAside imageModalAside--displayOff' : 'imageModalAside imageModalAside--displayOn'}>
            <button onClick={displayPrevImage} aria-label="Image précédente" className={imagesArrayLength <= 1 ? 'imageModalAside_leftButton imageModalAside_leftButton--displayOff' : 'imageModalAside_leftButton imageModalAside_leftButton--displayOn'}><FontAwesomeIcon icon={faChevronLeft} /></button>
            <figure className='imageModalAside_figure' onClick={closeModal}>
                <img src={imageModalSrc} alt='Florian_Langellier' className='imageModalAside_figure_image'/>
            </figure>
            <button onClick={displayNextImage} aria-label="Image suivante" className={imagesArrayLength <= 1 ? 'imageModalAside_rightButton imageModalAside_rightButton--displayOff' : 'imageModalAside_rightButton imageModalAside_rightButton--displayOn'}><FontAwesomeIcon icon={faChevronRight} /></button>
        </aside>
    )

}

export default ImageModal