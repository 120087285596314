import React from 'react'
import { createRoot } from 'react-dom/client';
import { HashRouter, BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './index.css';
import Home from './pages/Home/Home'
import Works from './pages/Works/Works'
import Biography from './pages/Biography/Biography'
import Series from './pages/Series/Series'
import Edit from './pages/Edit/Edit'
import Header from './components/Header/Header';
import Contact from './pages/Contact/Contact'
import Events from './pages/Events/Events'
import Error404 from './pages/Error404/Error404'
import { SeriesProvider } from './utils/SeriesContext';


const container = document.getElementById('root');
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <HashRouter>
      <SeriesProvider>
        <Header/>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/biography" element={<Biography/>} />
          <Route path="/series" element={<Series/>} />
          <Route path="/edit" element={<Edit/>} />
          <Route path="/events" element={<Events/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/works/:id" element={<Works/>} />
          <Route path="*" element={<Error404/>} />
        </Routes>
      </SeriesProvider>
    </HashRouter>
  </React.StrictMode>
);