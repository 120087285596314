import './BioEditForm.scss'
import React, { useRef, useState, useEffect, useContext } from 'react'
import { SeriesContext } from '../../utils/SeriesContext'
import { API_URL } from '../../utils/constants'
import DOMPurify from 'dompurify';


function BioEditForm () {
    
    const inputMainImageFileRef = useRef(null);
    const inputBioRef = useRef (null);
    const inputEnglishBioRef = useRef (null);
    const [handleMainSampleSystem, setHandleMainSampleSystem] = useState(false);
    const [oneMainImageSample, setOneMainImageSample] = useState('');
    const [mainImageFile, setMainImageFile] = useState(null);
    const { handleLoadInfos, hideLoader, displayLoader } = useContext(SeriesContext);
    const { mainInfos } = useContext(SeriesContext);
    const [bioEditModalMode, setBioEditModalMode] = useState('add');
    const [handleOkAlertMessage, setHandleOkAlertMessage] = useState(false);
    const [handleErrorAlertMessage, setHandleErrorAlertMessage] = useState(false);
    const cleanedBiography = DOMPurify.sanitize(mainInfos[0].biography);
    const cleanedEnglishBiography = DOMPurify.sanitize(mainInfos[0].englishBiography);

    /*--------------------------------
    ----------- MODE EDIT OU ADD -----
    --------------------------------*/

    useEffect(() => {
        if (!mainInfos || mainInfos.length === 0) {
            setBioEditModalMode('add');
        } else {
            setBioEditModalMode('edit');
        }
    }, [mainInfos]);

    /*-------------------------------------------------
    ----------- FONCTION MODIFICATION BIO--------------
    -------------------------------------------------*/

    async function bioEditSubmit(event) {
        event.preventDefault();
        displayLoader();
        try {
            const bioFormData = new FormData();
    
            bioFormData.append('biography', inputBioRef.current.value);
            bioFormData.append('englishBiography', inputEnglishBioRef.current.value);
    
            // Vérifiez si un fichier a été sélectionné avant de l'ajouter
            if (mainImageFile != null) {
                bioFormData.append('image', mainImageFile);
            }
            
            const response = await fetch(`${API_URL}/api/maininfos/`+ mainInfos[0]._id, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('1')}`
                },
                body: bioFormData,
            });
    
            if (!response.ok) {
                hideLoader();
                displayErrorAlertMessage();
                const errorData = await response.json();
                throw new Error(errorData.error);       
            }

            handleLoadInfos();
            setHandleMainSampleSystem (false);
            hideLoader();
            displayOkAlertMessage();
            

        } catch (error) {
            console.error(error);
            hideLoader();
            displayErrorAlertMessage();

        }
    }

    /*------------------------------------------
    ----------- FONCTION AJOUT BIO--------------
    ------------------------------------------*/

    async function bioAddSubmit(event) {
        event.preventDefault();
        displayLoader();
        try {
            const bioFormData = new FormData();

            bioFormData.append('biography', inputBioRef.current.value);
            bioFormData.append('englishBiography', inputEnglishBioRef.current.value);
    
            // Vérifiez si un fichier a été sélectionné avant de l'ajouter
            if (mainImageFile != null) {
                bioFormData.append('image', mainImageFile);
            }

            const response = await fetch(`${API_URL}/api/maininfos/`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('1')}`
                },
                body: bioFormData,
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                hideLoader();
                displayErrorAlertMessage ()
                throw new Error(errorData.error);
            }

            handleLoadInfos();
            setHandleMainSampleSystem (false);
            hideLoader();
            displayOkAlertMessage();


        } catch (error) {
            console.error(error);
            console.log(error);
            hideLoader();
            displayErrorAlertMessage();
        }
    }

    /*-------------------------------------------------
    ----------- FONCTION CREATION SAMPLE IMAGE---------
    -------------------------------------------------*/
    function addOneMainImageSample() {
        const selectedFile = inputMainImageFileRef.current.files[0];
        
        //CONDITION POUR AFFICHER OU CACHER BOUTONS VALID FICHIER IMAGE
        if (selectedFile) {
            console.log('ça marche');
            setOneMainImageSample(URL.createObjectURL(selectedFile));
            setHandleMainSampleSystem (true)
            setMainImageFile(selectedFile);
        } else {
            setHandleMainSampleSystem (false)
        }
    }

    /*-------------------------------------------------
    ----------- FONCTION ANNULATION -------------------
    -------------------------------------------------*/
    function cancelMainImageUpload(event) {
        event.preventDefault();
        setHandleMainSampleSystem (false);
        resetFields();
    }


    /*--------------------------------------------
    -----FONCTION RÉINITIALISATION DES CHAMPS-----
    --------------------------------------------*/
    function resetFields () {
            if (!mainInfos || mainInfos.length === 0) {
                return
            } else {
            inputBioRef.current.value = cleanedBiography.replace(/<br>/g, "\n");
            setMainImageFile ('');
        }
    }

    /*------------------------------------------
    ----- AFFICHAGES DES MESSAGES D'ALERTE -----
    ------------------------------------------*/
    function displayOkAlertMessage () {
        setHandleOkAlertMessage(true);
        setTimeout(function () {
            setHandleOkAlertMessage(false);
        }, 4000);
    }
    function displayErrorAlertMessage () {
        setHandleErrorAlertMessage(true);
        setTimeout(function () {
            setHandleErrorAlertMessage(false);
        }, 4000);
    }
    
    /*-------------
    ----- JSX -----
    -------------*/

    return (
        <div className='bioEditForm'>
            <form className='bioEditForm_form' 
            onSubmit={bioEditModalMode==='add'? bioAddSubmit : bioEditSubmit }>
                        <div className='bioEditForm_form_biography'>
                            <label className='bioEditForm_form_biography_label' htmlFor='inputBioRef'>BIOGRAPHIE_FR_</label>
                            <textarea className='bioEditForm_form_biography_textarea' id='inputBioRef' ref={inputBioRef} defaultValue={!mainInfos || mainInfos.length === 0 ? '' : cleanedBiography.replace(/<br>/g, "\n")} ></textarea>
                        </div>
                        <div className='bioEditForm_form_biography'>
                            <label className='bioEditForm_form_biography_label' htmlFor='inputEnglishBioRef'>BIOGRAPHIE_EN_</label>
                            <textarea className='bioEditForm_form_biography_textarea' id='inputEnglishBioRef' ref={inputEnglishBioRef} defaultValue={!mainInfos || mainInfos.length === 0 ? '' : cleanedEnglishBiography.replace(/<br>/g, "\n")} ></textarea>
                        </div>
                        <div className='bioEditForm_form_imageField'>
                            <input className='bioEditForm_form_imageField_input' type='file' id='inputMainImageFile' onChange={addOneMainImageSample} name="image" ref={inputMainImageFileRef} style={{ display: 'none' }} ></input>
                            <div className={handleMainSampleSystem === false ? 'bioEditForm_form_imageField_sampleSystem serieEditForm_form_imageField_sampleSystem--displayOff' : 'serieEditForm_form_imageField_sampleSystem serieEditForm_form_imageField_sampleSystem--displayOn'}>
                                <img src={oneMainImageSample} alt='sample' className='bioEditForm_form_imageField_sampleSystem_image'/>
                            </div>
                            <label className='bioEditForm_form_imageField_labelEdit' htmlFor='inputMainImageFile'>{bioEditModalMode==='edit' ? 'MODIFIER L\'IMAGE PRINCIPALE' : 'AJOUTER UNE IMAGE PRINCIPALE'}</label>
                        </div>
                        <div className='bioEditForm_form_buttons'>
                            <button aria-label="Valider" className='bioEditForm_form_buttons_validateButton' type="submit">VALIDER</button>
                            <button aria-label="Annuler" className='bioEditForm_form_buttons_cancelButton' onClick={cancelMainImageUpload}>ANNULER</button>
                        </div>
                        <p className={handleOkAlertMessage === true ? 'bioEditForm_form_okAlert--displayOn' : 'bioEditForm_form_okAlert--displayOff'}>Les infos ont bien été modifiées !</p>
                        <p className={handleErrorAlertMessage === true ? 'bioEditForm_form_errorAlert--displayOn' : 'bioEditForm_form_errorAlert--displayOff'}>Erreur lors de la modification des données...</p>
            </form>
            <img src={!mainInfos || mainInfos.length === 0 ? '' : mainInfos[0].mainImageUrl} alt={!mainInfos || mainInfos.length === 0 ? '' : mainInfos[0].mainImageUrl} className='bioEditForm_imageSample'/>
        </div>
    )
}

export default BioEditForm