import "./Biography.scss"
import { useContext } from 'react'
import { SeriesContext } from '../../utils/SeriesContext'
import DOMPurify from 'dompurify';

function Biography() {

    const { mainInfos, language } = useContext(SeriesContext);
    const mainInfosObject = mainInfos[0];
    const cleanedBiography = DOMPurify.sanitize(mainInfosObject.biography);
    const cleanedEnglishBiography = DOMPurify.sanitize(mainInfosObject.englishBiography);

    // Ne rien afficher si mainInfos n'existe pas ou est vide.
    if (!mainInfos || mainInfos.length === 0) {
        return null; 
    }

    return (
        <section className='bioSection'>
            <p className='bioSection_paragraph' dangerouslySetInnerHTML={{ __html: language === 'EN' ? cleanedEnglishBiography : cleanedBiography }}>
            </p>
        </section>
    )
}

export default Biography