import React, { createContext, useState, useEffect } from 'react';
import { API_URL } from './constants'
export const SeriesContext = createContext()
export const SeriesProvider = ({ children }) => {

    /*---------------------------------
    ----- MISE À JOUR ÉTAT D'AUTH -----
    ---------------------------------*/
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const setLoggedIn = () => {
        setIsAuthenticated(true) ;
    };
    const setLoggedOut = () => {
        setIsAuthenticated(false) ;
    };

    // GESTION STATE LOCAL LANGUE 
    const [language, setLanguage] = useState('EN');
    
    // GESTION STATE LOCAL SERIES + RELOAD 
    const [seriesList, setSeriesList] = useState([]);
    const [loadSeries, setLoadSeries] = useState(false);
    const handleLoadSeries = () => { 
        setLoadSeries(loadSeries === false ? true : false);
    };
    
    // GESTION STATE LOCAL MAIN INFOS + RELOAD 
    const [mainInfos, setMainInfos] = useState([]);
    const [loadInfos, setLoadInfos] = useState(false);
    const handleLoadInfos = () => { 
        setLoadInfos(loadInfos === false ? true : false);
    };

    // GESTION STATE LOCAL EVENEMENTS + RELOAD 
    const [eventsList, setEventsList] = useState([]);
    const [loadEvents, setLoadEvents] = useState(false);
    const handleLoadEvents = () => { 
        setLoadEvents(loadEvents === false ? true : false);
    }

    //GET SERIES
    useEffect(() => {
        fetch(`${API_URL}/api/series/`)
        .then(res => res.json())
        .then(data => setSeriesList(data))
        .catch((error)=>console.log(error.message))
    },[loadSeries]);

    //GET EVENTS
    useEffect(() => {
        fetch(`${API_URL}/api/events/`)
        .then(res => res.json())
        .then(data => setEventsList(data))
        .catch((error)=>console.log(error.message))
    },[loadEvents]);

    //GET MAIN INFOS
    useEffect(() => {
        fetch(`${API_URL}/api/maininfos/`)
        .then(res => res.json())
        .then(data => setMainInfos(data))
        .catch((error)=>console.log(error.message))
    },[loadInfos]);

    //DEFINITION STATE SERIE EN SURBRILLANCE
    const [selectedSerie, setSelectedSerie] = useState('');
    function handleSelectedSerie(serie) {
        setSelectedSerie(serie.title);
        console.log(selectedSerie)
    }

    //AFFICHAGE DU LOADER
    const [loaderDisplay, setLoaderDisplay] = useState(false);
    function displayLoader() {
        setLoaderDisplay(true);
    }

    function hideLoader() {
        setLoaderDisplay(false);
    }

    return (
        <SeriesContext.Provider value={{ seriesList, setSeriesList, eventsList, setEventsList, mainInfos, setMainInfos, handleLoadSeries, loadSeries, handleLoadInfos, handleLoadEvents, handleSelectedSerie, setSelectedSerie, selectedSerie, setLoggedIn, setLoggedOut, isAuthenticated, loaderDisplay, setLoaderDisplay, hideLoader, displayLoader, language, setLanguage}}>
            {children}
        </SeriesContext.Provider>
    )
}