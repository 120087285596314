import './Header.scss'
import { Link, useNavigate } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'
import { SeriesContext } from '../../utils/SeriesContext'
import AuthModal from '../AuthModal/AuthModal'

function Header() {

    const { seriesList, selectedSerie, eventsList, isAuthenticated, setLoggedOut, language, setLanguage } = useContext(SeriesContext)
    const [seriesListState, setSeriesListState] = useState('listClosed');
    const [selectedPage, setSelectedPage] = useState('');
    const navigate = useNavigate();

    /*-------------------------------------------------------
    ----- USE EFFECT POUR ECOUTER EVENEMENT KONAMI CODE -----
    -------------------------------------------------------*/

    useEffect(() => {
        document.addEventListener('keydown', keyHandler, false);
    
        return () => {
          document.removeEventListener('keydown', keyHandler, false);
        };
      }, []);

    /*--------------------------------------------------------
    ----- IMPLÉMENTATION DU KONAMI CODE POUR MODALE AUTH -----
    --------------------------------------------------------*/

    let konamiPattern = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'];
    let current = 0;
    const keyHandler = (event) => {
        if (konamiPattern.indexOf(event.key) < 0 || event.key !== konamiPattern[current])  {
            current = 0;
            return;
        }
        current++;
        if (konamiPattern.length === current) {
            current = 0;
            handleAuthModal();
        }
    }

    /*-------------------------------------------------------
    ----- GESTION OUVERTURE/FERMETURE DE LA MODALE AUTH -----
    -------------------------------------------------------*/

    const [authModalDisplay, setAuthModalDisplay] = useState(false);

    const handleAuthModal = () => {
        setAuthModalDisplay(authModalDisplay === false ? true : false );
    }

    /*------------------------------------------------------
    ----- GESTION OUVERTURE/FERMETURE DE LA NAV SERIES -----
    ------------------------------------------------------*/

    function openSeriesList() {
        setSeriesListState('listOpened')
    }

    function closeSeriesList() {
        if (seriesListState === 'listOpened') {
            setSeriesListState('listClosed')
        }
    }

    /*------------------------------
    ----- OUVERTURE CONFIRMBOX -----
    ------------------------------*/
    const [confirmBoxState, setConfirmBoxState] = useState (false);
    
    function openConfirmBox(index) {
        setConfirmBoxState (true);
    }

    /*------------------------------------
    ----- FERMETURE CONFIRMBOX -----
    ------------------------------------*/

    function closeConfirmBox() {
        setConfirmBoxState (false);
    }


    /*-------------
    ----- JSX -----
    -------------*/

    return (
        <header className='header'>
            <h1 className='header_title'>FLORIAN LANGELLIER</h1>
            <nav className='header_nav'>
                <ul className='header_nav_list'>
                    <li className='header_nav_list_item'><h2 onClick={() => {
                        closeSeriesList();
                        setSelectedPage('home')
                        }}>
                        <Link to="/" className={selectedPage === 'home' ? 'header_nav_list_item_link header_nav_list_item_link--active' : 'header_nav_list_item_link header_nav_list_item_link--inactive'}>{language==='EN' ? 'HOME' :'ACCUEIL'}</Link></h2>
                    </li>
                    <li className='header_nav_list_item'><h2 onClick={() => { 
                        closeSeriesList();
                        setSelectedPage('biography')
                        }}>
                        <Link to="/biography" className={selectedPage === 'biography' ? 'header_nav_list_item_link header_nav_list_item_link--active' : 'header_nav_list_item_link header_nav_list_item_link--inactive'}>{language==='EN' ? 'ABOUT' :'À PROPOS'}</Link></h2>
                    </li>
                    <li className={eventsList.length === 0 ? 'header_nav_list_item header_nav_list_item--displayOff' : 'header_nav_list_item header_nav_list_item--displayOn'}><h2 onClick={() => { 
                        closeSeriesList();
                        setSelectedPage('events')
                        }}>
                        <Link to="/events" className={selectedPage === 'events' ? 'header_nav_list_item_link header_nav_list_item_link--active' : 'header_nav_list_item_link header_nav_list_item_link--inactive'}>{language==='EN' ? 'EVENTS' :'ÉVÈNEMENTS'}</Link></h2>
                    </li>
                    <li className='header_nav_list_item'><h2 onClick={() => {
                        openSeriesList();
                        setSelectedPage('series')
                        }}>
                        <Link to="/series" className={selectedPage === 'series' ? 'header_nav_list_item_link header_nav_list_item_link--active' : 'header_nav_list_item_link header_nav_list_item_link--inactive'}>{language==='EN' ? 'WORKS' :'SERIES'}</Link></h2>
                    </li>
                    <li className='header_nav_list_item'><h2 onClick={() => {
                        closeSeriesList();
                        setSelectedPage('contact')
                        }}>
                        <Link to="/contact" className={selectedPage === 'contact' ? 'header_nav_list_item_link header_nav_list_item_link--active' : 'header_nav_list_item_link header_nav_list_item_link--inactive'}>CONTACT</Link></h2>
                    </li>
                    <li 
                        className={isAuthenticated === false ?'header_nav_list_item--displayOff' : 'header_nav_list_item--displayOn'}
                        // className={isAuthenticated === false ? 'header_nav_list_item header_nav_list_item--displayOff' : 'header_nav_list_item header_nav_list_item--displayOn'}
                        ><h2 onClick={() => {
                        closeSeriesList();
                        setSelectedPage('edit')
                        }}>
                        <Link to="/edit" 
                        className={selectedPage === 'edit' ? 'header_nav_list_item_link header_nav_list_item_link--active' : 'header_nav_list_item_link header_nav_list_item_link--inactive'}
                        >EDIT</Link></h2>
                    </li>
                    <li className={isAuthenticated === false ? 'header_nav_list_item header_nav_list_item--displayOff' : 'header_nav_list_item header_nav_list_item--displayOn'}>
                        <h2 onClick={() => {
                        closeSeriesList();
                        openConfirmBox ();
                        }}
                        className={selectedPage === 'logout' ? 'header_nav_list_item_link header_nav_list_item_link--active' : 'header_nav_list_item_link header_nav_list_item_link--inactive'}>LOGOUT</h2>
                    </li>
                    <li className='header_nav_list_item header_nav_list_item_link header_nav_list_item_link--grey'><h2 onClick={() => {
                        setLanguage((prevLanguage) => (prevLanguage === 'FR' ? 'EN' : 'FR'));
                        }}>
                        {language==='EN' ? 'FR' :'EN'}</h2>
                    </li>
                    <li className='header_nav_list_item'>
                        <a href='https://www.instagram.com/florianlangellier/' rel="noreferrer" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='header_nav_list_item_instaLink'>
                                <path
                                    d="M256,49.471c67.266,0,75.233.257,101.8,1.469,24.562,1.121,37.9,5.224,46.778,8.674a78.052,78.052,0,0,1,28.966,18.845,78.052,78.052,0,0,1,18.845,28.966c3.45,8.877,7.554,22.216,8.674,46.778,1.212,26.565,1.469,34.532,1.469,101.8s-0.257,75.233-1.469,101.8c-1.121,24.562-5.225,37.9-8.674,46.778a83.427,83.427,0,0,1-47.811,47.811c-8.877,3.45-22.216,7.554-46.778,8.674-26.56,1.212-34.527,1.469-101.8,1.469s-75.237-.257-101.8-1.469c-24.562-1.121-37.9-5.225-46.778-8.674a78.051,78.051,0,0,1-28.966-18.845,78.053,78.053,0,0,1-18.845-28.966c-3.45-8.877-7.554-22.216-8.674-46.778-1.212-26.564-1.469-34.532-1.469-101.8s0.257-75.233,1.469-101.8c1.121-24.562,5.224-37.9,8.674-46.778A78.052,78.052,0,0,1,78.458,78.458a78.053,78.053,0,0,1,28.966-18.845c8.877-3.45,22.216-7.554,46.778-8.674,26.565-1.212,34.532-1.469,101.8-1.469m0-45.391c-68.418,0-77,.29-103.866,1.516-26.815,1.224-45.127,5.482-61.151,11.71a123.488,123.488,0,0,0-44.62,29.057A123.488,123.488,0,0,0,17.3,90.982C11.077,107.007,6.819,125.319,5.6,152.134,4.369,179,4.079,187.582,4.079,256S4.369,333,5.6,359.866c1.224,26.815,5.482,45.127,11.71,61.151a123.489,123.489,0,0,0,29.057,44.62,123.486,123.486,0,0,0,44.62,29.057c16.025,6.228,34.337,10.486,61.151,11.71,26.87,1.226,35.449,1.516,103.866,1.516s77-.29,103.866-1.516c26.815-1.224,45.127-5.482,61.151-11.71a128.817,128.817,0,0,0,73.677-73.677c6.228-16.025,10.486-34.337,11.71-61.151,1.226-26.87,1.516-35.449,1.516-103.866s-0.29-77-1.516-103.866c-1.224-26.815-5.482-45.127-11.71-61.151a123.486,123.486,0,0,0-29.057-44.62A123.487,123.487,0,0,0,421.018,17.3C404.993,11.077,386.681,6.819,359.866,5.6,333,4.369,324.418,4.079,256,4.079h0Z"/>
                                <path
                                    d="M256,126.635A129.365,129.365,0,1,0,385.365,256,129.365,129.365,0,0,0,256,126.635Zm0,213.338A83.973,83.973,0,1,1,339.974,256,83.974,83.974,0,0,1,256,339.973Z"/>
                                <circle
                                    cx="390.476" cy="121.524" r="30.23"/>
                            </svg>
                        </a>
                    </li>
                </ul>
            </nav>
            <nav className={seriesListState === 'listClosed'? 'header_series--closed' : 'header_series--opened'}>
                <ul className='header_series_list'>
                    {seriesList.map((serie) => (
                        <li key={`${serie._id}`}><Link to={serie._id ? `/works/${serie._id}`:"*"} className={serie.title === selectedSerie? 'header_series_list_item header_series_list_item--active' : 'header_series_list_item header_series_list_item--inactive'}><h3>{serie.title}</h3></Link></li>
                    ))}
                </ul>
            </nav>
            <AuthModal handleAuthModal={handleAuthModal} authModalDisplay={authModalDisplay}/>
            <div className={confirmBoxState===false ? 'header_logOutConfirmBox header_logOutConfirmBox--displayOff' : 'header_logOutConfirmBox header_logOutConfirmBox--displayOn'}>
                <p className='header_logOutConfirmBox_label'>Vous ne serez plus connecté...</p>
                <div className='header_logOutConfirmBox_buttons'>
                    <button aria-label="Se déconnecter" onClick={() => {
                        setLoggedOut();
                        closeConfirmBox ();
                        navigate('/');
                        }}>OK</button>
                    <button aria-label="Annuler" onClick={() => closeConfirmBox () }>CANCEL</button>
                </div>
            </div>
        </header>
    )   
}

export default Header