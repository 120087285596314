import './Gallery.scss'
import ImageModal from '../ImageModal/ImageModal'
import { useState, useContext } from 'react'
import { SeriesContext } from '../../utils/SeriesContext'
import DOMPurify from 'dompurify';

function Gallery({serie}) {

    const [imageModalDisplay, setImageModalDisplay] = useState(false);
    const [imageModalSrc, setImageModalSrc] = useState ('');
    const { language } = useContext(SeriesContext);

    const cleanedSerieDescription = DOMPurify.sanitize(serie.description);
    const cleanedSerieEnglishDescription = DOMPurify.sanitize(serie.englishDescription);

    const imagesArrayLength = serie.images.length;
    const currentIndex = serie.images.findIndex (image => image.imageUrl === imageModalSrc);

    //DÉFINITION IMAGE PRÉCÉDENTE
    let prevImageIndex = currentIndex === 0 ? imagesArrayLength-1 : currentIndex -1;
    const prevImage = serie.images[prevImageIndex]

    //DÉFINITION IMAGE SUIVANTE
    let nextImageIndex = currentIndex === imagesArrayLength-1 ? 0 : currentIndex +1;
    const nextImage = serie.images[nextImageIndex]

    function displayPrevImage () {
        setImageModalSrc (prevImage.imageUrl);
    }

    function displayNextImage () {
        setImageModalSrc (nextImage.imageUrl);
    }

    function openImageModal () {
        setImageModalDisplay (true)
    }

    /*-------------
    ----- JSX -----
    -------------*/
    
    return (
        <section className="gallery">
            <h3 className='gallery_title' >{serie.title}</h3>
            <p className='gallery_description' dangerouslySetInnerHTML={{ __html: language === 'EN' ? cleanedSerieEnglishDescription : cleanedSerieDescription }}></p>
            <ul className="gallery_container">
                {serie.images.map((image) => (
                    <li key={`${image._id}`} className="gallery_container_imageBox" onClick={() => {
                        setImageModalSrc (image.imageUrl);
                        openImageModal()
                        }}>
                        <img src= {image.imageUrl} alt={`Florian_Langellier_${image._id}`} className="gallery_container_imageBox_image"/>
                    </li>
                ))}
            </ul>
            <ImageModal imageModalDisplay={imageModalDisplay} setImageModalDisplay={setImageModalDisplay} imageModalSrc={imageModalSrc} displayPrevImage={displayPrevImage} displayNextImage={displayNextImage} imagesArrayLength={imagesArrayLength}/>
        </section>
    )
}

export default Gallery