import './Edit.scss'
import { API_URL } from '../../utils/constants'
import { useState, useContext } from 'react'
import { SeriesContext } from '../../utils/SeriesContext'
import SerieEditForm from '../../components/SerieEditForm/SerieEditForm'
import BioEditForm from '../../components/BioEditForm/BioEditForm'
import EventsEditForm from '../../components/EventsEditForm/EventsEditForm'
import ConfirmBox from '../../components/ConfirmBox/ConfirmBox'
import Loader from '../../components/Loader/Loader'


function Edit() {

    const { handleLoadSeries, handleLoadEvents, isAuthenticated, loaderDisplay } = useContext(SeriesContext);

    /*----------------------------
    ----- GESTION CONFIRMBOX -----
    ----------------------------*/
    
    //GESTION DU MODE (EVENT ou SERIE)
    const [deleteMode, setDeleteMode] = useState('event');
    //GESTION OUVERTURE 
    const [confirmBoxState, setConfirmBoxState] = useState (false);
    function closeConfirmBox () {
        setConfirmBoxState(false)
    }
    
    /*------------------------------------------------
    ----- SUPPRESSION SERIES VIA CONFIRMBOX ----------
    ------------------------------------------------*/

    const [serieToDelete, setSerieToDelete] = useState ({});
    
    function deleteSerie (serie) {
        fetch(`${API_URL}/api/series/${serie._id}`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('1')}`,
          },
        })
        .then ((response) => {
            if(response.ok) {
                console.log(response);
            }
            handleLoadSeries();
            closeConfirmBox();
        })
        .catch ((error)=>console.log(error.message))
    }

    /*-----------------------------------------------
    ----- SUPPRESSION EVENEMENTS VIA CONFIRMBOX -----
    -----------------------------------------------*/

    // FONCTION SUPPRESSION
    const [eventToDelete, setEventToDelete] = useState ({});
    function deleteEvent(eventData) {
        console.log('deleteEvent');
        closeConfirmBox();
        console.log(eventToDelete)
        fetch(`${API_URL}/api/events/${eventData._id}`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('1')}`,
          },
        })
        .then ((response) => {
            if(response.ok) {
                console.log(response);
            }
            handleLoadEvents();
        })
        .catch ((error)=>console.log(error.message))
    }

    /*-------------
    ----- JSX -----
    -------------*/
    return (
        <div>
            <aside className={isAuthenticated === false ? 'editAside editAside--displayOff' : 'editAside editAside--displayOn'}>
                <div className='editAside_topContainer'>
                    <div className="editAside_topContainer_editSeries">
                        <h3>ÉDITION SÉRIES</h3>
                        <SerieEditForm confirmBoxState={confirmBoxState} setConfirmBoxState={setConfirmBoxState} setDeleteMode={setDeleteMode} deleteMode={deleteMode} closeConfirmBox={closeConfirmBox} setSerieToDelete={setSerieToDelete}/>
                    </div>
                    <div className="editAside_topContainer_editEvents">
                        <h3>ÉDITION ÉVÈNEMENTS</h3>
                        <EventsEditForm confirmBoxState={confirmBoxState} setConfirmBoxState={setConfirmBoxState} setDeleteMode={setDeleteMode} deleteMode={deleteMode} closeConfirmBox={closeConfirmBox} setEventToDelete={setEventToDelete}/>
                    </div>
                </div>
                <div className="editAside_editBio">
                    <h3>ÉDITION BIOGRAPHIE + IMAGE PRINCIPALE</h3>
                    <BioEditForm/>
                </div>
                <ConfirmBox confirmBoxState={confirmBoxState} closeConfirmBox={closeConfirmBox} serieToDelete={serieToDelete} eventToDelete={eventToDelete} deleteMode={deleteMode} deleteSerie={deleteSerie} deleteEvent={deleteEvent}/>
                <Loader loaderDisplay={loaderDisplay} />
            </aside>
            <div className={isAuthenticated === false ? 'editAside_notAuthText editAside_notAuthText--displayOn' : 'editAside_notAuthText editAside_notAuthText--displayOff'}>
                <p>ACCÈS NON-AUTORISÉ</p>
            </div>
        </div>
    )
}

export default Edit