import { useState, useContext, useRef } from 'react'
import { SeriesContext } from '../../utils/SeriesContext'
import  './EventsEditForm.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrashCan , faPenToSquare} from '@fortawesome/free-solid-svg-icons'
import { API_URL } from '../../utils/constants'


function EventsEditForm ({ setDeleteMode, setConfirmBoxState, setEventToDelete}) { 

    const inputEventNameRef = useRef(null);
    const inputEventDescriptionRef = useRef(null);
    const inputEventDateRef = useRef(null);
    const inputEventLocationRef = useRef(null);
    const inputEventUrlRef = useRef(null);

    /*----------------------------------------------------------------------------
    ----- GESTION OUVERTURE / FERMETURE MODALE / RÉINITIALISATION DES CHAMPS -----
    ----------------------------------------------------------------------------*/
    const [displayEventModal, setDisplayEventModal] = useState (false);
    //FERMETURE MODALE
    function closeModal() {
        setEventObject({});
        setEventModalMode('add'); // Réinitialiser serieObject lorsque la modale est fermée
        setDisplayEventModal(false);
    }
    // RESET CHAMPS DU FORMULAIRE
    function resetFields () {
        setEventObject({});
        inputEventNameRef.current.value='';
        inputEventDescriptionRef.current.value='';
        inputEventDateRef.current.value='';
        inputEventLocationRef.current.value='';
        inputEventUrlRef.current.value='';
        setDisplayNameError(false);
        setDisplayDateError(false);
        setDisplayError(false);
    };
    //ANNULATION
    function cancelSettings () {
        closeModal();
        resetFields();
    } 

    /*------------------------------------------
    ----- GESTION COMMUNE MODE EDIT ou ADD -----
    ------------------------------------------*/
    //PASSER EN MODE MODIF ou ADD
    const [eventModalMode, setEventModalMode] = useState('add');
    
    function storeEvent (eventData) {
        setEventObject(eventData);
    }

    /*------------------
    ----- MODE ADD -----
    ------------------*/

    function handleAddClick () {
        setEventModalMode('add')
        setDisplayEventModal(true);
        resetFields ()
    }

    /*-------------------
    ----- MODE EDIT -----
    -------------------*/

    //STOCK VALEURS D'UN EVENEMENT POUR PRÉ-REMPLIR CHAMPS
    const [eventObject, setEventObject] = useState({});


    //RÉCUPÉRATION DES EVENEMENTS via useContext
    const { handleLoadEvents, eventsList } = useContext(SeriesContext);

    // OUVERTURE MODE MODIF
    async function handleModifyClick(eventData) {
        try {
            await storeEvent(eventData);
            // handleLoadEvents();
        } catch (error) {
            console.log(error.message)
        }
        setEventModalMode('edit');
        setDisplayEventModal(true);
    }

    /*-------------------------
    ----- AJOUT ÉVÈNEMENT -----
    -------------------------*/

    function addEventSubmit (event) {
        event.preventDefault();

        if (!inputEventNameRef.current.value || !inputEventDateRef.current.value) {
            if (!inputEventNameRef.current.value) {
            setDisplayNameError(true)
            }
            if (!inputEventDateRef.current.value) {
            setDisplayDateError(true)
            }
            return
        }


        let eventFormData = {
            'name' : inputEventNameRef.current.value,
            'description' : inputEventDescriptionRef.current.value,
            'date' : inputEventDateRef.current.value,
            'location' : inputEventLocationRef.current.value,
            'eventUrl':inputEventUrlRef.current.value
        }
    
        fetch(`${API_URL}/api/events/`, {
            method: 'POST',
            body: JSON.stringify(eventFormData),
            headers: {
                'Content-Type':'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('1')}`,
        }   
        })
        .then ((response) => {
            // resetFields();
            console.log(response);
            handleLoadEvents();
            setDisplayEventModal(false);
            resetFields();
            setDisplayError(false);
            setDisplayNameError(false);
            setDisplayDateError(false);
        })
        .catch((error) => {
            console.error(error);
            setDisplayError(true)
        })
    }


    /*---------------------------------
    ----- MODIFICATION ÉVÈNEMENT ------
    ---------------------------------*/

    async function editEventSubmit (event) {
        event.preventDefault();

        if (!inputEventNameRef.current.value || !inputEventDateRef.current.value) {
            if (!inputEventNameRef.current.value) {
                setDisplayNameError(true)
            } 
            if (!inputEventDateRef.current.value) {
                setDisplayDateError(true)
            } 
            return
        }

        try {
            let eventFormData = {
                'name' : inputEventNameRef.current.value,
                'description' : inputEventDescriptionRef.current.value,
                'date' : inputEventDateRef.current.value,
                'location' : inputEventLocationRef.current.value,
                'eventUrl':inputEventUrlRef.current.value
            }
            console.log(eventFormData);
            const response = await fetch(`${API_URL}/api/events/` + eventObject._id, {
                method: "PUT",
                body: JSON.stringify(eventFormData),
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('1')}`,
                    'Content-Type':'application/json'
                }  
            });
    
            if (!response.ok) {
                setDisplayError(true);
                const errorData = await response.json();
                throw new Error(errorData.error);
            }

            handleLoadEvents();
            setDisplayEventModal(false);
            setDisplayError(false);
            setDisplayNameError(false);
            setDisplayDateError(false);

        } catch (error) {
            console.error(error);
            setDisplayError(true);
        }
    }


    /*-------------------------------------
    ----- OUVERTURE CONFIRM BOX SUPPR -----
    -------------------------------------*/

    function eventConfirmBoxDisplay (eventData) {
        setConfirmBoxState (true)
        setDeleteMode ('event');
        setEventToDelete (eventData);
    }

    /*-------------------------------------------------
    ----- GESTION OUVERTURE / FERMETURE ERRORTEXT -----
    -------------------------------------------------*/
    const [displayNameError, setDisplayNameError] = useState (false);
    const [displayDateError, setDisplayDateError] = useState (false);
    const [displayError, setDisplayError] = useState (false);


    /*-------------
    ----- JSX -----
    -------------*/

    return (  
        <div className='eventsEditForm'>
            <ul className='eventsEditForm_list'>
                {eventsList.map((eventData) => (
                    <li className='eventsEditForm_list_item' key={eventData._id}>
                        <p className='eventsEditForm_list_item_name'>{eventData.name}</p>
                        <div className='eventsEditForm_list_item_buttons'>
                            <button aria-label="Supprimer l'évènement" className='eventsEditForm_list_item_buttons_supprButton' onClick={() => eventConfirmBoxDisplay (eventData)} type='button'><FontAwesomeIcon icon={faTrashCan}/></button>
                            <button aria-label="Modifier l'évènement" className='eventsEditForm_list_item_buttons_editButton' onClick={() => {
                                handleModifyClick(eventData);
                                }}><FontAwesomeIcon icon={faPenToSquare} /></button>
                        </div>
                    </li>
                    ))}
                    <li >
                        <button aria-label="Ajouter un évènement" className='eventsEditForm_list_addButton' onClick={() => handleAddClick()}>+ AJOUTER UN ÉVÈNEMENT</button>
                    </li>
            </ul>
            <form className={displayEventModal === false? "eventsEditForm_form eventsEditForm_form--displayOff" : "eventsEditForm_form eventsEditForm_form--displayOn"} onSubmit={eventModalMode === 'edit' ? editEventSubmit : addEventSubmit}>
                <div className='eventsEditForm_form_container'>
                    <div className='eventsEditForm_form_name'> 
                        <label className='eventsEditForm_form_name_label' htmlFor='inputEventName'>NOM *</label>
                        <input
                            className='eventsEditForm_form_name_input'
                            type='text'
                            id='inputEventName'
                            ref={inputEventNameRef}
                            name='name'
                            value={eventModalMode === 'edit' ? eventObject.name : null}
                            onChange={(e) => setEventObject({ ...eventObject, name: e.target.value })}
                            >  
                        </input>
                        <p className={displayNameError === true ? "eventsEditForm_alertTextStyle--displayOn" : "eventsEditForm_alertTextStyle--displayOff"}>Ce champs ne peut pas être vide.</p>
                    </div>
                    <div className='eventsEditForm_form_description'>
                        <label className='eventsEditForm_form_description_label' htmlFor='inputEventDescription'>DESCRIPTION</label>
                        <textarea
                            className='eventsEditForm_form_description_textarea'
                            type='text'
                            id='inputEventDescription'
                            ref={inputEventDescriptionRef}
                            name='description'
                            value = {eventModalMode === 'edit' ? eventObject.description : null}
                            onChange={(e) => setEventObject({ ...eventObject, description: e.target.value })}
                            >  
                        </textarea>
                    </div>
                    <div className='eventsEditForm_form_date'>
                        <label className='eventsEditForm_form_date_label' htmlFor='inputEventDate'>DATE *</label>
                        <input
                            type="datetime-local"
                            className='eventsEditForm_form_date_input'
                            id='inputEventDate'
                            ref={inputEventDateRef}
                            name='date'
                            value={eventModalMode === 'edit' ? eventObject.date : null}
                            onChange={(e) => setEventObject({ ...eventObject, date: e.target.value })}
                            >  
                        </input>
                        <p className={displayDateError === true ? "eventsEditForm_alertTextStyle--displayOn" : "eventsEditForm_alertTextStyle--displayOff"}>Ce champs ne peut pas être vide.</p>
                    </div>
                    <div className='eventsEditForm_form_location'>
                        <label className='eventsEditForm_form_location_label' htmlFor='inputEventLocation'>LIEU</label>
                        <input
                            className='eventsEditForm_form_location_input'
                            type='text'
                            id='inputEventLocation'
                            ref={inputEventLocationRef}
                            name='location'
                            value={eventModalMode === 'edit' ? eventObject.location : null}
                            onChange={(e) => setEventObject({ ...eventObject, location: e.target.value })}
                            >  
                        </input>
                    </div>
                    <div className='eventsEditForm_form_link'>
                        <label className='eventsEditForm_form_link_label' htmlFor='inputEventUrl'>LIEN URL</label>
                        <input
                            className='eventsEditForm_form_link_input'
                            type='text'
                            id='inputEventUrl'
                            ref={inputEventUrlRef}
                            name='eventUrl'
                            value={eventModalMode === 'edit' ? eventObject.eventUrl : null}
                            onChange={(e) => setEventObject({ ...eventObject, eventUrl: e.target.value })}
                            >  
                        </input>
                    </div>
                    <div className='eventsEditForm_form_buttons'>
                        <button aria-label="Valider" className='eventsEditForm_form_buttons_validateButton' type='submit'>VALIDER</button>
                        <button aria-label="Annuler" className='eventsEditForm_form_buttons_cancelButton' type='button' onClick={() => cancelSettings()}>ANNULER</button>
                    </div>
                    <p className={displayError === false ? "eventsEditForm_alertTextStyle--displayOff" : "eventsEditForm_alertTextStyle--displayOn"}>Une erreur est survenue.</p>
                </div>
            </form>
        </div>
        )
    }
    
    export default EventsEditForm