import './SerieEditForm.scss'
import React, { useRef, useState, useContext, useEffect } from 'react'
import { SeriesContext } from '../../utils/SeriesContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrashCan , faPenToSquare} from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from 'uuid'
import { API_URL } from '../../utils/constants'
import DNDGallery from '../DNDGallery/DNDGallery'
import DOMPurify from 'dompurify';


function SerieEditForm({setConfirmBoxState, setDeleteMode, setSerieToDelete}) {

    const inputFileRef = useRef(null);
    const inputTitleRef = useRef(null);
    const inputDescriptionRef = useRef(null);
    const inputEnglishDescriptionRef = useRef(null);
    
    /*----------------------------------------------------------------------------
    ----- GESTION OUVERTURE / FERMETURE MODALE / RÉINITIALISATION DES CHAMPS -----
    ----------------------------------------------------------------------------*/

    const [displayModal, setDisplayModal] = useState (false);
    //FERMETURE MODALE
    function closeModal() {
        setSerieObject(null);
        setModalMode('add'); // Réinitialiser serieObject lorsque la modale est fermée
        setDisplayModal(false);
    }
    // RESET CHAMPS DU FORMULAIRE
    function resetFields () {
        inputFileRef.current.value = null;
        inputTitleRef.current.value = '';
        inputDescriptionRef.current.value = '';
        inputEnglishDescriptionRef.current.value = '';
        setImageFiles([]);
    };
    //ANNULATION
    function cancelSettings () {
        closeModal();
        resetFields();
        setDisplayError(false);
        setDisplayFileError(false);
        setDisplayTitleError(false);
    } 
    

    /*------------------------------------------
    ----- GESTION COMMUNE MODE EDIT ou ADD -----
    ------------------------------------------*/

    // STATE LOCAL IMAGES FILES
    const [imageFiles, setImageFiles] = useState([]);
    // GESTION STATE LOCAL
    const [modalMode, setModalMode] = useState('add');

    /*--------------------
    ----- MODE EDIT ------
    --------------------*/

    // OUVERTURE MODE MODIF
    async function handleModifyClick(serie) {
        try {
            await storeSerie(serie);
            await setImageFiles(serie.images);
            handleLoadSeries();
            setDisplayModal(true);
            setModalMode('edit');
            setMainImageIndex(serie.mainImageIndex);
        } catch (error) {
            console.log(error.message)
        }
    }
    //RÉCUPÉRATION DES SÉRIES et des FONCTIONS LIÉES AU LOADER via useContext
    const { seriesList, handleLoadSeries, hideLoader, displayLoader } = useContext(SeriesContext);
    


    //STOCK VALEURS D'UNE SERIE POUR PRÉ-REMPLIR CHAMPS
    const [serieObject, setSerieObject] = useState(null);
    const serie = serieObject;
    const cleanedSerieDescription = serie ? DOMPurify.sanitize(serie.description) : '';
    const cleanedSerieEnglishDescription = serie ? DOMPurify.sanitize(serie.englishDescription) : '';
    function storeSerie (serie) {
        setSerieObject(serie);
    }
    //MISE A JOUR DES SAMPLES D'IMAGES
    useEffect(() => {
        if (serieObject) {
            setImageFiles(serieObject.images);
        }
    }, [serieObject]);

    /*------------------
    ----- MODE ADD -----
    ------------------*/

    // OUVERTURE MODE AJOUT
    function handleAddClick() {
        resetFields();
        setDisplayModal(true);
        setModalMode('add');
    }

    // AFFICHAGE D'UN PREMIER SAMPLE IMAGE APRÈS CHOIX
    const [handleSampleSystem, setHandleSampleSystem] = useState (false);
    const [oneImageSample, setOneImageSample] = useState ('');
    const [newImage, setNewImage] = useState (null);
    

    function addOneImageSample() {
        const image = inputFileRef.current.files[0];
      
        if (image) {
          const id = uuidv4(); // Générez un identifiant unique
          image._id = id;
          image.sampleImageUrl= URL.createObjectURL(image);
          const newImage = image; // Assurez-vous de conserver l'image
          setOneImageSample(newImage.sampleImageUrl);
          setHandleSampleSystem(true);
          setNewImage (newImage);
          console.log(image._id);
        } else {
            setHandleSampleSystem(false);
        }
    }


    // AJOUT IMAGE SUPPLEMENTAIRE et AFFICHAGE DYNAMIQUE
    const handleImageUpload = (e) => {
        // const selectedFile = inputFileRef.current.files[0];
        if (newImage) {
            const updatedImageFiles = [...imageFiles, newImage];
            setImageFiles(updatedImageFiles);
            setSerieObject({
                ...serieObject,
                images: updatedImageFiles
            });
        }
        setHandleSampleSystem(false);
    };


    // ANNULER IMAGE UPLOAD
    const cancelImageUpload = () => {
        setOneImageSample('');
        setHandleSampleSystem (false)
    };

    /*---------------------
    ----- AJOUT SERIE -----
    ---------------------*/

    function addSubmit (event) {
        event.preventDefault();
        if (!inputTitleRef.current.value || !imageFiles || imageFiles.length<=0) {
            if (!inputTitleRef.current.value ) {
                setDisplayTitleError(true)
            } 
            if (!imageFiles || imageFiles.length<=0) {
                console.log('pas d image');
                setDisplayFileError(true)
            } 
            return
        }
        displayLoader();
        const serieFormData = new FormData();
        imageFiles.forEach((imageFile, index) => {
            serieFormData.append('images', imageFile);
        });
        serieFormData.append('title', inputTitleRef.current.value);
        serieFormData.append('description', inputDescriptionRef.current.value);
        serieFormData.append('englishDescription', inputEnglishDescriptionRef.current.value);
        serieFormData.append('mainImageIndex', mainImageIndex);
        console.log(serieFormData);
        fetch(`${API_URL}/api/series`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('1')}`,
              },
            body: serieFormData,
        })
            .then (() => {
                handleLoadSeries();
                setDisplayModal(false);
                hideLoader();
                resetFields();
                setDisplayError(false);
                setDisplayFileError(false);
                setDisplayTitleError(false);
            })
            .catch((error) => {
                setDisplayError(true);
                console.error(error);
                hideLoader();
            })
    }

    /*-----------------------------
    ----- MODIFICATION SERIE ------
    -----------------------------*/

    // CREATION FORMDATA POUR REQUETE PUT
    async function createFormData() {
        const serieFormData = new FormData();
        serieFormData.append('title', inputTitleRef.current.value);
        serieFormData.append('description', inputDescriptionRef.current.value);
        serieFormData.append('englishDescription', inputEnglishDescriptionRef.current.value);
        serieFormData.append('mainImageIndex', mainImageIndex);
        const newImageFiles = Array.from(imageFiles); // Créer une copie du tableau d'origine
        const imagesWithIndex = newImageFiles.map((image, index) => ({
            index,
            image
        }));
        imagesWithIndex.forEach(({ index, image }) => {
            if (image instanceof File) {
                serieFormData.append('images', image);
                serieFormData.append('fileIndexes', index)
            } else {
                serieFormData.append(`existingImages[${index}]`, JSON.stringify(image));
            }
        });
        return serieFormData;
    }
    
    //FONCTION MODIFICATION GLOBALE
    async function editSubmit(event) { 
        event.preventDefault();
        if (!inputTitleRef.current.value || !imageFiles || imageFiles.length<=0) {
            if (!inputTitleRef.current.value) {
                setDisplayTitleError(true)
            } 
            if (!imageFiles || imageFiles.length<=0) {
                setDisplayFileError(true)
            } 
            return
        }
        displayLoader();
        try {
            const serieFormData = await createFormData();
            const response = await fetch(`${API_URL}/api/series/` + serie._id, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('1')}`,
                },
                body: serieFormData,
            });
            if (!response.ok) {
                setDisplayError(true);
                const errorData = await response.json();
                throw new Error(errorData.error);
            }
            handleLoadSeries();
            setDisplayModal(false);
            setDisplayError(false);
            setDisplayFileError(false);
            setDisplayTitleError(false);
            hideLoader();
            resetFields();
        } catch (error) {
            console.error(error);
            setDisplayError(true);
            hideLoader();
        }
    }


    /*-------------------------------------
    ----- OUVERTURE CONFIRM BOX SUPPR -----
    -------------------------------------*/

    function serieConfirmBoxDisplay (serie) {
        setConfirmBoxState (true);
        setDeleteMode ('serie');
        setSerieToDelete (serie);
    }
    
    /*------------------------------------------------------
    ----- DÉFINITION DE L'IMAGE PRINCIPALE DE LA SÉRIE -----
    ------------------------------------------------------*/
    const [mainImageIndex, setMainImageIndex] = useState (0);

    /*-------------------------------------------------
    ----- GESTION OUVERTURE / FERMETURE ERRORTEXT -----
    -------------------------------------------------*/
    const [displayTitleError, setDisplayTitleError] = useState (false);
    const [displayFileError, setDisplayFileError] = useState (false);
    const [displayError, setDisplayError] = useState (false);
    
    /*---------
    --- JSX ---
    ---------*/

    return (
        <div>
            <ul className='serieEditList'>
                {seriesList.map((serie) => (
                    <li className='serieEditList_item' key={serie._id}>
                        <p className='serieEditList_item_title'>{serie.title}</p>
                        <div className='serieEditList_item_buttons'>
                            <button aria-label="Supprimer la série" className='serieEditList_item_buttons_supprButton' onClick={() => serieConfirmBoxDisplay(serie)} type='button'><FontAwesomeIcon icon={faTrashCan}/></button>
                            <button aria-label="Modifier la série" className='serieEditList_item_buttons_modifButton' onClick={() => {
                            handleModifyClick(serie);
                            }}><FontAwesomeIcon icon={faPenToSquare} /></button>
                        </div>
                    </li>
                    ))}
                    <li key="addButton">
                        <button aria-label="Ajouter une série" className='serieEditList_item_addButton' onClick={() => handleAddClick()}>+ AJOUTER UNE SÉRIE</button>
                    </li>
            </ul>
            <div className={displayModal === false? "serieEditForm serieEditForm--displayOff" : "serieEditForm serieEditForm--displayOn"}>
                <div className='serieEditForm_mainContainer'> 
                    <DNDGallery imageFiles={imageFiles} setImageFiles={setImageFiles} serie={serie} mainImageIndex={mainImageIndex} setMainImageIndex={setMainImageIndex}/>
                    <form className='serieEditForm_form' onSubmit={modalMode === 'edit' ? editSubmit : addSubmit}>
                        <div className='serieEditForm_form_title'>
                            <label className='serieEditForm_form_title_label' htmlFor='inputTitle'>NOM DE LA SÉRIE *</label>
                            <input
                                className='serieEditForm_form_title_input'
                                type='text'
                                id='inputTitle'
                                ref={inputTitleRef}
                                value={modalMode === 'edit' ? serie.title : null }
                                onChange={(e) => setSerieObject({ ...serieObject, title: e.target.value, images: imageFiles })}
                                >
                            </input>
                            <p className={displayTitleError === true ? "serieEditForm_alertTextStyle--displayOn" : "serieEditForm_alertTextStyle--displayOff"}>Ce champs ne peut pas être vide.</p>
                        </div>
                        <div className='serieEditForm_form_description'>
                            <label className='serieEditForm_form_description_label' htmlFor='inputDescription'>DESCRIPTION DE LA SÉRIE_FR</label>
                            <textarea
                                className='serieEditForm_form_description_textarea'
                                id='inputDescription'
                                ref={inputDescriptionRef}
                                value={modalMode === 'edit' ? cleanedSerieDescription.replace(/<br>/g, "\n") : null}
                                onChange={(e) => setSerieObject({ ...serieObject, description: e.target.value, images: imageFiles })}
                            ></textarea>
                        </div>
                        <div className='serieEditForm_form_description'>
                            <label className='serieEditForm_form_description_label' htmlFor='inputEnglishDescription'>DESCRIPTION DE LA SÉRIE_EN</label>
                            <textarea
                                className='serieEditForm_form_description_textarea'
                                id='inputEnglishDescription'
                                ref={inputEnglishDescriptionRef}
                                value={modalMode === 'edit' ? cleanedSerieEnglishDescription.replace(/<br>/g, "\n") : null}
                                onChange={(e) => setSerieObject({ ...serieObject, englishDescription: e.target.value, images: imageFiles })}
                            ></textarea>
                        </div>
                        <div className='serieEditForm_form_imageField'>
                            <label className={handleSampleSystem === false ? 'serieEditForm_form_imageField_label serieEditForm_form_imageField_label--displayOn' : 'serieEditForm_form_imageField_label serieEditForm_form_imageField_label--displayOff' } htmlFor='inputFile'>+ AJOUTER UNE IMAGE</label>
                            <input className='serieEditForm_form_imageField_input' type='file' id='inputFile' onChange={addOneImageSample} name="images" ref={inputFileRef} style={{ display: 'none' }} ></input>
                            <div className={handleSampleSystem === false ? 'serieEditForm_form_imageField_sampleSystem serieEditForm_form_imageField_sampleSystem--displayOff' : 'serieEditForm_form_imageField_sampleSystem serieEditForm_form_imageField_sampleSystem--displayOn'}>
                                <img src={oneImageSample} alt='sample' className='serieEditForm_form_imageField_sampleSystem_image'/>
                                <div className='serieEditForm_form_imageField_sampleSystem_buttons'>
                                    <button aria-label="Ajouter l'image" onClick={handleImageUpload} type="button">AJOUTER</button>
                                    <button aria-label="Annuler" onClick={cancelImageUpload} type="button">ANNULER</button>
                                </div>
                            </div>
                        </div>
                        <div className='serieEditForm_form_buttons'>
                            <button aria-label="Annuler" className='serieEditForm_form_buttons_cancelButton' onClick={() => cancelSettings()} type='button'>ANNULER</button>
                            <button aria-label="Valider" className='serieEditForm_form_buttons_validateButton' type='submit'>VALIDER</button>
                        </div>
                        <p className={displayFileError === true ? "serieEditForm_alertTextStyle--displayOn" : "serieEditForm_alertTextStyle--displayOff"}>Vous devez uploader au moins une image.</p>
                        <p className={displayError === true ? "serieEditForm_alertTextStyle--displayOn" : "serieEditForm_alertTextStyle--displayOff"}>Une erreur est survenue.</p>
                    </form>
                </div>
            </div>    
        </div>
        
    )
}

export default SerieEditForm