import './Error404.scss'

function Error404() {

    return (
        <div className='error404'>
            <p className='error404_title'>404</p>
            <p className='error404_text'> Oops! It seems you are lost...</p>
        </div>
    )
}

export default Error404