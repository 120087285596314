import './Home.scss'
import MainImage from '../../components/MainImage/MainImage'

function Home() {

    return (
        <section className='homeSection'>
            <MainImage/>
        </section>
    )
}

export default Home