import './Contact.scss'
// import ContactForm from '../../components/ContactForm/ContactForm'

function Contact() {

    return (
        <aside className='contactAside'>
            {/* <ContactForm/> */}
            <div className='contactAside_datas'>
                <p className='contactAside_datas_name'>Florian Langellier</p><br/>
                <p className='contactAside_datas_description'>Photographe indépendant basé à Toulouse.</p><br/>
                <address className='contactAside_datas_address'>
                    <p className='contactAside_datas_address_line'>18 bis rue des cyclamens</p><br/>
                    <p className='contactAside_datas_address_line'>31500 Toulouse</p>
                </address>
                <p className='contactAside_datas_mail' >florian.langellier@gmail.com</p><br/>
                <p className='contactAside_datas_tel'>+33 (0) 6 41 25 94 10</p>
            </div>
        </aside>
    )
}

export default Contact